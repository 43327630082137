import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPalette,
  faDatabase,
  faQuestionCircle,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

const ServiceItem = ({ icon, title, children, className }) => (
  <div
    className={`py-12 px-8 md:py-8 flex flex-col items-center border rounded ${className}`}
  >
    <header className="flex flex-col items-center relative">
      <FontAwesomeIcon icon={icon} size="2x" />
      <h3 className="text-lg font-bold mt-4">{title}</h3>
      <div
        className="top-0 bottom-0 left-0 right-0 absolute pointer-events-none mix-blend-overlay"
        style={{
          backgroundImage: `linear-gradient(45deg, black, white)`,
        }}
      ></div>
    </header>
    <p className="paragraph text-center mt-2">{children}</p>
  </div>
)

const PlusIcon = () => (
  <div
    className="w-12 h-12 bg-gray-900 text-white text-4xl flex items-center justify-center rounded shadow-md bg-blend-overlay"
    style={{
      textShadow: "0 1px 0 #000",
      backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5))`,
    }}
  >
    +
  </div>
)

const PlusColumn = () => (
  <>
    {/* Horizontal plus icon */}
    <div className="w-12 -mx-6 relative hidden md:flex flex-col justify-center">
      <PlusIcon />
    </div>

    {/* Vertical plus icon */}
    <div className="h-12 -my-6 relative flex md:hidden w-full justify-center">
      <PlusIcon />
    </div>
  </>
)

export default function Diensten() {
  return (
    <Layout>
      <Seo title="Diensten" />
      <div className="container py-8">
        <h1 className="h1">
          Het alles-in-1 pakket voor een{" "}
          <em className="highlight">professionele online uitstraling</em>
        </h1>
        <p className="paragraph">
          Uw bedrijf dient online vindbaar te zijn en een website werkt vaak als
          eerste indruk. Een professionele en betrouwbare uitstraling is waar u
          op oogt. Webdesign, webhosting, en support: het is allemaal inbegrepen
          bij Thomas' Webdesign.
        </p>

        <div className="row mt-2 md:-m-2 md:mt-4">
          <div className="column p-2 md:w-1/3 flex">
            <ServiceItem
              title="Webdesign"
              icon={faPalette}
              className="text-orange-500"
            >
              Een professioneel ontwerp dat goed aansluit bij uw bedrijf. Werkt
              goed op mobiel en desktop.
            </ServiceItem>
          </div>
          <PlusColumn className="hidden md:block" />
          <div className="column md:p-2 md:w-1/3 flex">
            <ServiceItem
              title="Webhosting"
              icon={faDatabase}
              className="text-green-500"
            >
              Wij zorgen dat uw site online komt, en blijft. Daarnaast zorgen we
              dat de site razendsnel laadt.
            </ServiceItem>
          </div>
          <PlusColumn className="hidden md:block" />
          <div className="column md:p-2 md:w-1/3 flex">
            <ServiceItem
              title="Support"
              icon={faQuestionCircle}
              className="text-blue-500"
            >
              Wij staan altijd voor u klaar wanneer u aanpassingen wilt maken of
              andere vragen heeft.
            </ServiceItem>
          </div>
        </div>

        <footer className="bg-gray-300 p-8 mt-4 md:mt-8 rounded-sm">
          <div className="flex flex-wrap items-center -m-2">
            <main className="flex-grow p-2">
              <h2 className="h2 mb-0">
                Benieuwd naar voorbeelden van ons werk?
              </h2>
              <p className="text">
                Wij gaan op professionele wijze te werk. Bekijk voorbeelden uit
                ons portfolio.
              </p>
            </main>
            <aside className="p-2">
              <Link to="/portfolio" className="button">
                Bekijk portfolio
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </Link>
            </aside>
          </div>
        </footer>
      </div>
    </Layout>
  )
}
